<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model ref="searchRef" style="flex:1;"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="type" label="商品分类">
              <a-select v-model="searchForm.type" placeholder="商品分类">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                  >{{ goodsType[item] }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear
                       v-model="searchForm.goods_name"
                       placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="sku_number"
                               label="SKU码">
              <a-input allowClear
                       v-model="searchForm.sku_number"
                       placeholder="SKU码"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button class="ml-8" @click="searchData" type="primary" html-type="submit">搜索</a-button>
        <a-button class="ml-2" @click="resetForm">重置</a-button>
      </div>
    </div>

    <base-table ref="tableRef"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :row-selection="rowSelection"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex items-center mb-2">
          <a-select v-model="searchForm.outlets_city_adcode" style="width: 100px;" @change="searchData">
            <a-select-option
              v-for="(item, index) in manageCityList"
              :key="index"
              :value="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select>
          <h4 class="text-lg font-bold ml-4 mb-0">城市门店商品上架</h4>
        </div>
        <div>
          <a-button type="link" class="ml-1" style="padding:0;" :disabled="!rowSelection.selectedRowKeys.length"
                  @click="handlerAddGoods(null,true)">批量上架</a-button>
          <a-button type="link" class="ml-6" style="padding:0;" :disabled="!rowSelection.selectedRowKeys.length"
                  @click="handlerRemoveGoods(null,true)">批量下架</a-button>
        </div>
      </template>
      <template #not_on_sale_outlets_number="{ record }">
        <span class="mr-4">{{record.not_on_sale_outlets_number}}</span>
        <a-button type="link" style="padding:0;"
                  :disabled="record.not_on_sale_outlets_number==0"
                  @click="handlerAddGoods(record.goods_id)">上架</a-button>
      </template>
      <template #on_sale_outlets_number="{ record }">
        <span class="mr-4">{{record.on_sale_outlets_number}}</span>
        <a-button type="link" style="padding:0;" 
                  :disabled="record.on_sale_outlets_number==0"
                  @click="handlerRemoveGoods(record.goods_id)">下架</a-button>
        <a-button type="link" class="ml-4" style="padding:0;" 
                  :disabled="record.on_sale_outlets_number==0"
                  @click="handlerGetGoodStock(record)">查看库存</a-button>
      </template>
    </base-table>

    <add-shop-goods v-if="isShowAddGoods"
          :show.sync="isShowAddGoods"
          :adcode="searchForm.outlets_city_adcode"
          :goodIdList="selectedGoodIdList"
          @ok="handlerAddGoodsOK" />
    <good-stock v-if="isShowGoodStock"
          :show.sync="isShowGoodStock"
          :adcode="searchForm.outlets_city_adcode"
          :goodId="stockActiveId"
          :goodName="stockActiveName" />
  </div>
</template>

<script>

import AddShopGoods from "./components/add-shop-goods.vue"
import GoodStock from "./components/good-stock.vue"
import { 
  getRegionalGoodsList, 
  addRegionalCakeGoods,
  addRegionalOtherGoods,
  removeRegionalGoods
} from "@/api/regional/goods";
import {
  getFinanceCityList,
} from "@/api/outlets"
import pageData from "./columns";

import { goodsStatusList } from "@/utils/type";
export default {
  components: {
    AddShopGoods,
    GoodStock
  },
  data() {
    return {
      ...pageData,
      statusTypeList: goodsStatusList,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      tableData: [],
      manageCityList: [],
      searchForm: {
        sale_status: "",
        goods_name: "",
        sku_number: "",
        type: "",
        // status: "",
        page: 1,
        page_count: 40,
      },
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id);
          } else {
            this.rowSelection.selectedRowKeys =
              this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
      selectedGoodIdList: [],
      isShowAddGoods: false,

      stockActiveId: '',
      stockActiveName: '',
      isShowGoodStock: false,
    };
  },
  async mounted() {
    await this.getFinanceCityListData()
    this.searchData();
  },
  methods: {
    // 内部管理的城市列表
    async getFinanceCityListData () {
      let list
      try{
        list = JSON.parse(localStorage.getItem('cityList'))
      }catch(e){
        console.log(e)
      }
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
        this.searchForm.outlets_city_adcode = list[0].adcode
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      } 
    },
    searchData(){
      this.searchForm.page = 1
      this.initData()
    },
    async initData() {
      const { data, code } = await getRegionalGoodsList(this.searchForm);
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count
      }
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      this.initData();
    },

    async handlerRemoveGoods(gid, isMulti) {
      let ids
      if(isMulti){
        ids = (this.rowSelection.selectedRowKeys || []).map(id=>{
          return { goods_id: id }
        })
      }else{
        ids = [ {goods_id: gid} ]
      }
      if(ids && ids.length){
        let self = this
        this.$confirm({
          title: "对全部门店下架"+(ids.length>1?"这些":"这个")+"商品？",
          async onOk () {
            const { code } = await removeRegionalGoods({
              outlets_city_adcode: self.searchForm.outlets_city_adcode,
              off_shelves_goods_data: JSON.stringify(ids)
            });
            if (code == 0) {
              self.$message.success("已下架")
              self.rowSelection.selectedRowKeys = []
              self.initData()
            }
          },
          onCancel () { },
        })
      }
    },

    handlerAddGoods(gid, isMulti) {
      let ids = []
      if(isMulti){
        ids = this.rowSelection.selectedRowKeys || []
      }else{
        ids = [ gid ]
      }
      if(ids.length){
        let otherIds = this.tableData.filter(gd=>{
          return gd.type != 3
        }).map(gd=>{
          return gd.goods_id
        })
        let hasOther = false
        while(otherIds.length){
          let otherid = otherIds.pop()
          if(ids.includes(otherid)){
            hasOther = true
            break
          }
        }
        this.selectedGoodIdList = ids
        // 如果纯蛋糕 直接添加；否则提示设置库存
        if(!hasOther){
          this.addOnlyCakes()
        }else{
          this.isShowAddGoods = true
        }
      } 
    },

    async addOnlyCakes(){
      let self = this
      this.$confirm({
        title: "确定上架商品到全部门店？",
        async onOk () {
          let cake_goods_list = self.selectedGoodIdList.map(id=>{
            return { goods_id: id }
          })
          self.handlerAddGoodsOK({
            cake_goods_list: cake_goods_list
          })
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    async handlerAddGoodsOK(data) {
      let code1 = 0
      let code2 = 0
      if(data.cake_goods_list && data.cake_goods_list.length){
        let res1 = await addRegionalCakeGoods({
          outlets_city_adcode: this.searchForm.outlets_city_adcode,
          add_goods_data: JSON.stringify(data.cake_goods_list)
        })
        code1 = res1.code
      }
      if(data.other_sku_list && data.other_sku_list.length){
        let res2 = await addRegionalOtherGoods({
          outlets_city_adcode: this.searchForm.outlets_city_adcode,
          add_sku_data: JSON.stringify(data.other_sku_list)
        })
        code2 = res2.code
      }
      if(code1 == 0 && code2 == 0){
        this.isShowAddGoods = false
        this.$message.success("已上架")
        this.rowSelection.selectedRowKeys = []
        this.initData()
      }
    },

    handlerGetGoodStock(row){
      this.stockActiveId = row.goods_id
      this.stockActiveName = row.goods_name
      this.isShowGoodStock = true
    },

  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
