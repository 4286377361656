
export const rowKey = 'goods_id'
export const columns = [ 
  {
    title: '分类',
    dataIndex: 'type',
    align: 'center',
    width: "7%",
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatType(val)
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center'
  },
  {
    title: '小程序/网站',
    dataIndex: 'is_on_sale_shop',
    align: 'center',
    width: "9%",
    slots: {
      customRender: 'is_on_sale_shop'
    },
    slotsType: 'format',
    slotsFunc: (val) => {return val==2?"在售":""}
  },
  // {
  //   title: '小程序商城',
  //   dataIndex: 'is_on_sale_mall',
  //   align: 'center',
  //   width: "9%",
  //   slots: {
  //     customRender: 'is_on_sale_mall'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"在售":""}
  // },
  // {
  //   title: '网站',
  //   dataIndex: 'is_on_sale_website',
  //   align: 'center',
  //   width: "8%",
  //   slots: {
  //     customRender: 'is_on_sale_mall'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"在售":""}
  // },
  {
    title: '上架门店数',
    align: 'center',
    width: "12%",
    slots: {
      customRender: 'on_sale_outlets_number'
    },
    // slotsType: 'all',
    // slotsFunc: (item) => {return item.not_on_sale_outlets_number==0?"全部":item.on_sale_outlets_number}
  },
  {
    title: '未上架门店数',
    align: 'center',
    width: "12%",
    slots: {
      customRender: 'not_on_sale_outlets_number'
    },
    // slotsType: 'all',
    // slotsFunc: (item) => {return item.not_on_sale_outlets_number+item.on_sale_outlets_number}
  },
]

export const goodsType = {
  1: '软点',
  2: '西点',
  3: '蛋糕'
}

export function formatType (val) {
  return goodsType[val]
}

export default {
  rowKey,
  columns,
  goodsType
}
