<template>
  <a-modal
    :visible.sync="show"
    :title="goodName+' 库存'"
    destroyOnClose
    width="950px"
    :footer="null"
    @cancel="$emit('update:show', false)"
  >
    
    <base-table class="mb-3"
                :columnsData="baseColumns"
                :tableData="tableData"
                :noPage="true"
                :addHeight="10">
      <template #daily_refresh_stock="{ text }">
        <span>{{ text > 999 ? "999+" : text }}</span>
      </template>
      <template #stock="{ text }">
        <span>{{ text > 999 ? "999+" : text }}</span>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
import { rowSpan } from "@/utils/index"
import { 
  goodsOutletsStock
} from "@/api/regional/goods";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goodId: {
      type: [String,Number]
    },
    goodName: {
      type: String,
      default: ''
    },
    adcode: {
      type: [String,Number]
    },

  },
  data () {
    return {
      baseColumns: [
        {
          title: "网点",
          dataIndex: "outlets_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.outlets_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "每日最大库存",
          dataIndex: "daily_refresh_stock",
          align: "center",
          width: 120,
          slots: { customRender: "daily_refresh_stock" },
        },
        {
          title: "当前库存",
          dataIndex: "stock",
          align: "center",
          width: 120,
          slots: { customRender: "stock" },
        },
      ],
      tableData: [],
    }
  },

  mounted () {
    this.initData()
  },
  methods: {

    async initData () {
      const {code, data} = await goodsOutletsStock({
        goods_id: this.goodId,
        outlets_city_adcode: this.adcode,
      })
      if(code == 0){
        let list = []
        data.list.map(ol=>{
          ol.sku_list.map(sku=>{
            list.push(Object.assign(sku, {
              outlets_id: ol.outlets_id,
              outlets_name: ol.outlets_name,
            }))
          })
        })
        list = rowSpan(list, "outlets_name")

        this.tableData = list
      }
    },


  },
}
</script>
