import ajax from '@/utils/ajax.js'

/**
 * 获取区域的在售商品
 * @param {*} params 
 * @returns 
 */
export function getRegionalGoodsList(params) {
  return ajax.post('/RegionalOutletsGoods/getList',params)
}


/**
 * 给区域的门店上架商品：蛋糕
 * @param {*} params 
 * @returns 
 */
export function addRegionalCakeGoods(params) {
  return ajax.post('/RegionalOutletsGoods/onSaleCakeGoods',params)
}

/**
 * 给区域的门店上架商品：非蛋糕
 * @param {*} params 
 * @returns 
 */
export function addRegionalOtherGoods(params) {
  return ajax.post('/RegionalOutletsGoods/onSaleOtherGoods',params)
}

/**
 * 给区域的门店下架商品
 * @param {*} params 
 * @returns 
 */
export function removeRegionalGoods(params) {
  return ajax.post('/RegionalOutletsGoods/offShelvesGoods',params)
}

/**
 * 给区域的门店上架商品：查看商品sku
 * @param {*} params 
 * @returns 
 */
export function getGoodsSkuList(params) {
  return ajax.post('/RegionalOutletsGoods/getSkuListOnSaleUse',params)
}

/**
 * 上架门店的库存
 * @param {*} params 
 * @returns 
 */
export function goodsOutletsStock(params) {
  return ajax.post('/RegionalOutletsGoods/goodsOutletsStock',params)
}
