<template>
  <a-modal
    :visible.sync="show"
    title="设置库存，提交上架"
    destroyOnClose
    width="950px"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="flex justify-end mb-1">
      <a-button type="link" class="mr-6" size="small" style="padding:0;"
          @click="isShowMultiStock=true">批量填写库存数</a-button>
    </div>
    <base-table ref="saleGoods" class="mb-3"
                id="saleGoods"
                rowKey="sku_id"
                :columnsData="baseColumns"
                :tableData="tableData"
                :noPage="true"
                :addHeight="10">
      <template #max_stock="{record}">
        <a-input-number v-if="record.goods_type!=3" v-model="record.max_stock" @change="onChange" @input="onInput" />
      </template>
      <template #current_stock="{record}">
        <a-input-number v-if="record.goods_type!=3" v-model="record.current_stock" />
      </template>
    </base-table>

    <a-modal v-if="isShowMultiStock" :visible.sync="isShowMultiStock"
             title="批量填写库存"
             destroyOnClose
             width="550px"
             @ok="handleMultiStockOk"
             @cancel="isShowMultiStock=false">
      <div class="flex justify-between items-center mt-4 mb-6">
        <div class="flex items-center">
          <span class="mr-3">每日最大库存</span>
          <a-input-number style="min-width:150px;" v-model="multi_max_stock" placeholder="每日最大库存" />
        </div>
        <div class="flex items-center">
          <span class="mr-3">当前库存</span>
          <a-input-number style="min-width:150px;" v-model="multi_current_stock" placeholder="今日剩余库存" />
        </div>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { formatType } from "../columns"
import { rowSpan } from "@/utils/index"
import {
  getGoodsSkuList,
} from "@/api/regional/goods";
import { cutQuery } from '@/utils/ossImg'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goodIdList: {
      type: Array
    },
  },
  data () {
    return {
      baseColumns: [
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品分类",
          dataIndex: "goods_type",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: formatType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.flavor_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "最大库存",
          dataIndex: "max_stock",
          align: "center",
          width: 120,
          slots: { customRender: "max_stock" },
        },
        {
          title: "当前库存",
          dataIndex: "current_stock",
          align: "center",
          width: 120,
          slots: { customRender: "current_stock" },
        },
      ],
      tableData: [],

      isShowMultiStock: false,
      multi_max_stock: 0,
      multi_current_stock: 0,
    }
  },

  mounted () {
    this.initData()
  },
  methods: {

    async initData () {
      const { data, code } = await getGoodsSkuList({
        goods_ids: this.goodIdList.join(",")
      })
      if (code == 0) {
        // 组装表格所需数据
        let skuArr = []
        // data.list.filter(gd=>gd.goods_type!=3).map((goods) => {
        data.list.map((goods) => {
          // goods.is_cake = goods.goods_type==3
          goods.sku_data.map((item) => {
            let obj = goods
                obj.flavor_name = item.flavor_name
                obj.status = 1
            // if (obj.is_cake) {
            //   obj.max_stock = "999+"
            // }
            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, "goods_name")

        this.tableData = skuArr
      }
    },

    handleMultiStockOk(){
      this.tableData = this.tableData.map(sku=>{
        sku.max_stock     = this.multi_max_stock
        sku.current_stock = this.multi_current_stock
        return sku
      })
      this.isShowMultiStock = false
    },

    handleOk () {
      // let emptyStockIndex = this.tableData.findIndex(sku=>{
      //   if(sku.goods_type!=3){
      //     if(!sku.max_stock || !sku.current_stock){
      //       return true
      //     }
      //   }
      //   return false
      // })
      // if(emptyStockIndex > -1){
      //   this.$message.warning("请填写库存")
      //   return
      // }

      let self = this
      this.$confirm({
        title: "确定上架商品到全部门店？",
        async onOk () {
          self.onComfirm()
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    onInput(v){
      console.log("onInput", v)
    },
    onChange(v){
      console.log("onChange", v)
    },

    async onComfirm(){
      let cake_goods_list = []
      let other_sku_list = []
      this.tableData.map(sku=>{
        if(sku.goods_type==3){
          if(!cake_goods_list.includes(sku.goods_id)){
            cake_goods_list.push(sku.goods_id)
          }
        }else{
          other_sku_list.push({
             sku_id: sku.sku_id,
             max_stock: sku.max_stock||0,
             current_stock: sku.current_stock||0
          })
        }
      })
      cake_goods_list = cake_goods_list.map(id=>{
        return { goods_id: id }
      })
      this.$emit("ok", {
        cake_goods_list: cake_goods_list,
        other_sku_list: other_sku_list
      })
    },
  },
}
</script>
